import {createFeatureSelector, createSelector} from '@ngrx/store';
import {UserActivityLogState} from './user-activity-log.reducer';
import {selectActiveCourse} from './selectors';



export const selectUserActivityLogState = createFeatureSelector<UserActivityLogState>("userActivityLog");

export const selectUserActivityLogForActiveCourse = createSelector(
  selectActiveCourse,
  selectUserActivityLogState,
  (course, userActivityLog) => Object.values(userActivityLog.entities).filter(event => event.courseId === course.id)
)
