import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {AssignmentSolution} from '../models/assignment-solution.model';
import {createReducer, on} from '@ngrx/store';
import {AssignmentSolutionActions} from './action-types';

export interface AssignmentSolutionsState extends EntityState<AssignmentSolution> {

}

export const adapter: EntityAdapter<AssignmentSolution> = createEntityAdapter<AssignmentSolution>();

export const initialState: AssignmentSolutionsState = adapter.getInitialState();

export const assignmentSolutionsReducer = createReducer(
  initialState,
  on(AssignmentSolutionActions.assignmentSolutionLoaded, (state,{solution}) => adapter.addOne(solution, state)),

  on(AssignmentSolutionActions.assignmentSolutionPurged, (state, action) => adapter.removeOne(action.assignmentSolutionId, state) )
);


export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
