import {createFeatureSelector, createSelector} from '@ngrx/store';
import {selectActiveLesson} from './selectors';
import * as fromAssignmentSolution from './assignment-solution.reducer';
import {AssignmentSolutionsState} from './assignment-solution.reducer';


export const selectAssignmentSolutionsState = createFeatureSelector<AssignmentSolutionsState>("assignmentSolutions");


export const selectAllAssignmentSolutions = createSelector(
  selectAssignmentSolutionsState,
  fromAssignmentSolution.selectAll
);

export const selectActiveLessonAssigmentSolution = createSelector(
  selectActiveLesson,
  selectAllAssignmentSolutions,
  (lesson, solutions) => solutions.find(solution => solution.lessonId == lesson.id)
);
