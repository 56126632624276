import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: "root"
})
export class UiEventsService {

  private fullScreenSubject = new BehaviorSubject<boolean>(false);

  fullScreen$ = this.fullScreenSubject.asObservable();

  fullScreenMode(isFullScreenMode:boolean) {
    this.fullScreenSubject.next(isFullScreenMode);
  }

}
