import {Question} from './question.model';
import {Timestamp} from "@firebase/firestore";


export interface LessonQuestion extends Question {
  courseId:string;
  lessonId:string;
}

export function convertToMilliseconds(seconds: number, nanoseconds: number): number {

  const millisecondsFromSeconds = seconds * 1000;

  const millisecondsFromNanoseconds = nanoseconds / 1000000;

  return millisecondsFromSeconds + millisecondsFromNanoseconds;

}

export function compareQuestions(q1: LessonQuestion, q2:LessonQuestion) {

  let q1CreatedAt = q1.createdAt;
  let q2CreatedAt = q2.createdAt;

  if (q1.createdAt['_nanoseconds'] && q1.createdAt['_seconds']) {
    q1CreatedAt = Timestamp.fromMillis(convertToMilliseconds(q1CreatedAt['_seconds'], q1CreatedAt['_nanoseconds']))
  }

  if (q2CreatedAt['_nanoseconds'] && q2CreatedAt['_seconds']) {
    q2CreatedAt = Timestamp.fromMillis(convertToMilliseconds(q2CreatedAt['_seconds'], q2CreatedAt['_nanoseconds']))
  }

  return q2CreatedAt/*q2.createdAt*/.toMillis() - q1CreatedAt.toMillis() // q1.createdAt.toMillis();

}
