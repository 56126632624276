import {Timestamp} from '@firebase/firestore';

export interface Answer {
  id:string;
  questionId:string;
  courseId:string;
  lessonId:string;
  answerText:string;
  userId:string;
  userDisplayName:string;
  userPictureUrl:string;
  createdAt?:Timestamp;
}



export function compareAnswers(a1: Answer, a2:Answer) {

  const compareCourses = a1.courseId.localeCompare(a2.courseId);

  if (compareCourses!== 0) return compareCourses;

  const compareLessons = a1.lessonId.localeCompare(a2.lessonId);

  if (compareLessons !== 0) return compareLessons;

  return a1.createdAt.toMillis() - a2.createdAt.toMillis();
}
