import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../store';
import {selectTenantInfo} from '../../store/selectors';
import {TenantInfo} from '../../models/tenant.model';
import {isPlanAllowed} from './is-plan-allowed';


/**
 *
 * This directive shows or hides a feature from the user depending on the instructor pricing plan.
 *
 * These are the plans: Founder >= Pro >= Basic >= Free.
 *
 */

@Directive({
    selector: '[allowPlan]',
    standalone: true
})
export class AllowPlanDirective {

  private tenant: TenantInfo;
  private minimumPlan: string;
  private visible = false;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private store: Store<AppState>) {

    this.viewContainer.clear();

    this.store
      .pipe(
        select(selectTenantInfo)
      )
      .subscribe(
        tenant => {
          this.tenant = tenant;
          this.updateVisibility();
        }
      );


  }

  @Input()
  set allowPlan(minimumPlan: string) {
    this.minimumPlan = minimumPlan;
    this.updateVisibility();

  }

  updateVisibility() {

    if (!this.tenant?.instructorPricingPlan || !this.minimumPlan) {
      return;
    }

    const allowed = isPlanAllowed(this.tenant, this.minimumPlan);

    if (allowed && !this.visible) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.visible = true;
    }
    else if (!allowed && this.visible) {
      this.viewContainer.clear();
      this.visible = false;
    }
  }

}





